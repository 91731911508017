<template>
  <div class="page-container news-and-events">
    <!-- Section Our News & Events Start -->
    <div class="section-wrapper">
      <div class="content-wrapper">
        <Content1 :title="$t('ourNews&Events.title')"></Content1>
      </div>
    </div>
    <!-- Section Our News & Events End -->

    <!-- Section News and Events List Start -->
    <div
      v-intersect.once="onIntersect"
      class="section-wrapper"
      :class="{ 'pt-0': isMobile }"
    >
      <v-scroll-x-transition
        tag="div"
        :group="true"
        class="content-wrapper row"
      >
        <template v-for="(i, index) in newsAndEventsObj.data">
          <v-col :key="index" cols="12" md="6" lg="4" xl="3">
            <CardNewsAndEventSquare
              :title="i.title"
              :description="i.description"
              :created-at="i.createdAt"
              @click="onClickCard(i)"
            />
          </v-col>
        </template>
      </v-scroll-x-transition>
      <div
        v-if="!newsAndEventsObj.data || newsAndEventsObj.data.length < 1"
        class="text-center text-body-1"
      >
        {{ $t('message.underConstruction') }}
      </div>
      <div class="text-center">
        <v-btn
          v-if="
            newsAndEventsObj.pagination.currentPage !=
            newsAndEventsObj.pagination.lastPage
          "
          depressed
          tile
          color="primary"
          min-width="145"
          min-height="42"
          @click="loadMoreAction"
        >
          {{ $t('text.loadMore') }}
        </v-btn>
      </div>
    </div>
    <!-- Section News and Events List End -->

    <AppDialogNewsDetails
      v-model="dialogDetails.isShow"
      :title="dialogDetails.title"
      :content="dialogDetails.content"
      :created-at="dialogDetails.createdAt"
    />
  </div>
</template>

<script>
import { SHARED } from '@/constants'
import {
  NEWS_AND_EVENTS_GET_NEWS_AND_EVENTS,
  NEWS_AND_EVENTS_INITIAL_GET_NEWS_AND_EVENTS_STATE
} from '@/store/news-and-events.module'
import AppDialogNewsDetails from '@/components/dialog/AppDialogNewsDetails.vue'
import CardNewsAndEventSquare from '@/components/card/CardNewsAndEventSquare.vue'

export default {
  name: 'NewsAndEvents',
  components: {
    AppDialogNewsDetails,
    CardNewsAndEventSquare
  },
  data: () => ({
    defaultSearchCriteria: {
      perPage: 10
    },
    newsAndEventsObj: {
      data: [],
      pagination: {}
    },
    newsAndEventsList: [],
    isIntersecting: false,
    dialogDetails: {
      isShow: false,
      title: '',
      content: '',
      image: ''
    }
  }),
  computed: {
    newsAndEventsComplete() {
      return this.$store.state.newsAndEvents.newsAndEvents.complete
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  watch: {
    newsAndEventsComplete() {
      let response = this.$store.state.newsAndEvents.newsAndEvents
      if (response.complete) {
        this.getNewsAndEventsCompleteAction(response)
      }
    }
  },
  created() {
    this.initNewsAndEvents()
  },
  methods: {
    onClickCard(item) {
      if (item.link) window.open(item.link, '_blank')
      else if (item.description !== '-') {
        this.dialogDetails = {
          isShow: true,
          title: item.title,
          content: item.description,
          image: item.image,
          createdAt: item.createdAt
        }
      }
    },
    loadMoreAction() {
      let data = {
        perPage: this.defaultSearchCriteria.perPage,
        page: this.newsAndEventsObj.pagination.currentPage + 1
      }
      this.getNewsAndEvents(data)
    },
    onIntersect(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersecting = isIntersecting
      }
    },
    getImageUrl(url) {
      return url ? url : `${SHARED.BASE_URL}img/png/dummy-news-and-events.png`
    },
    getNewsAndEventsCompleteAction(response) {
      if (response.code == 0) {
        this.newsAndEventsObj.pagination = response.pagination

        response.data.forEach((element) => {
          this.newsAndEventsObj.data.push(element)
        })
      } else {
        console.log(response)
      }
      this.initialGetNewsAndEventsState()
    },
    getNewsAndEvents(data) {
      this.$store.dispatch(NEWS_AND_EVENTS_GET_NEWS_AND_EVENTS, { data })
    },
    initialGetNewsAndEventsState() {
      this.$store.dispatch(NEWS_AND_EVENTS_INITIAL_GET_NEWS_AND_EVENTS_STATE)
    },
    initNewsAndEvents() {
      let data = {
        perPage: this.defaultSearchCriteria.perPage,
        page: 1
      }
      this.getNewsAndEvents(data)
    }
  }
}
</script>

<style lang="scss">
.news-and-events {
  .news-and-event-thumbnail-img {
    height: 228px;
    width: 228px;
  }
}

@media (max-width: 959px) {
  .news-and-events {
    .news-and-event-thumbnail-img {
      height: 147px;
      width: 147px;
    }
  }
}
</style>
