<template>
  <v-card
    tile
    elevation="0"
    class="news-n-event-card"
    @click="$emit('click', $event)"
  >
    <div class="news-n-event-card__container py-8 px-5">
      <div class="news-n-event-card__content">
        <div class="news-n-event-card__date">
          {{ createdAt | date }}
        </div>

        <div class="news-n-event-card__title">
          {{ trimmedTitle }}
        </div>

        <div
          class="news-n-event-card__description"
          v-html="trimmedDescription"
        ></div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { uiHelper } from '@/utils'

export default {
  name: 'CardNewsAndEventSquare',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    createdAt: {
      type: String,
      default: ''
    }
  },
  computed: {
    trimmedTitle() {
      const title = uiHelper.removeHtmlTags(this.title)

      if (title) {
        const newTitle = title.substring(0, 40)
        if (title.length > 40) {
          return `${newTitle}...`
        } else return newTitle
      } else return title
    },
    trimmedDescription() {
      const desc = uiHelper.removeHtmlTags(this.description)

      if (desc) {
        const newDesc = desc.substring(0, 150)
        if (desc.length > 150) {
          return `${newDesc}...`
        } else return newDesc
      } else return desc
    }
  }
}
</script>

<style lang="scss">
.news-n-event-card {
  position: relative;
  height: 350px;

  .news-n-event-card__container {
    height: 100%;
    width: 100%;
    border: 1px solid #c4c4c4;

    .news-n-event-card__content {
      position: relative;
      z-index: 1;
      font-style: normal;
      max-height: 100%;
      overflow: hidden;

      .news-n-event-card__date {
        margin-bottom: 0.875rem;
      }

      .news-n-event-card__title {
        font-size: 1.5rem;
        color: var(--v-title-base);
        font-weight: bold;
      }

      .news-n-event-card__description {
        font-size: 1.125rem;
        margin-top: 1.5em;
        color: var(--v-label-base);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #fecf8d;
      transition: height 0.4s ease-in-out;
    }

    &:hover {
      .news-n-event-card__content {
        color: var(--v-tertiary-base);
      }

      &::after {
        height: 100%;
      }
    }
  }
}
</style>
