<template>
  <v-dialog
    :value="value"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="800"
  >
    <v-card>
      <v-card-title
        class="text-h5 lighten-2 text-wrap"
        style="word-break: normal"
      >
        {{ title }}</v-card-title
      >

      <span class="px-6 text-label"> {{ createdAt | date }}</span>

      <v-card-text class="pt-4">
        <img
          :src="getImageUrl(image)"
          style="max-height: 450px; width: 100%; object-fit: cover"
        />
        <div v-html="content" class="text-body-1 pt-2"></div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          depressed
          tile
          min-width="145"
          min-height="42"
          color="primary"
          class="title--text font-weight-black mx-auto"
          @click="$emit('input', false)"
        >
          {{ $t('button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { SHARED } from '@/constants'

export default {
  name: 'AppDialogNewsDetails',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    createdAt: {
      type: String,
      default: ''
    }
  },
  methods: {
    getImageUrl(url) {
      return url ? url : `${SHARED.BASE_URL}img/png/dummy-news-and-events.png`
    }
  }
}
</script>

<style></style>
